<template lang="pug">
div
  van-sticky
    van-search(v-model='searchKey', placeholder='请输入搜索关键词' @search="onSearch" shape="round")
    van-dropdown-menu
      van-dropdown-item(v-model='value', :options='option')
      van-dropdown-item(title='筛选', ref='item')
        //- van-switch-cell(v-model='switch1', title='包邮')
        //- van-switch-cell(v-model='switch2', title='团购')
        van-button(block='', type='info', @click='onConfirm') 确认
  pull-refresh-list(
    ref="list"
    v-on:update:list="list = $event"
    :getListMethod="getListData"
    )
    van-swipe-cell(v-for='item in list' :key='item.id' :name='item.id')
      van-card.van-hairline--bottom(
        :title='item.name'
        :desc='item.desc'
        :num='item.num' 
        :price='formatPrice(item.price)'
        :thumb='item.image'
        @click="$router.push({name:'detail', query: {id: item.fur_id_poi_furnitures}})"
        )
        //- template(#tags)
          van-tag(plain type='danger') 标签
          van-tag(plain type='danger') 标签
        //- template(#footer)
        //-   van-button(icon="cart-o" round @click="addToCart($event, item)")
      template(#right='')
        van-button.delete-button(square='', text='删除', type='danger'  @click='removeToCollect(item)')
</template>

<script>
import { Sticky } from 'vant'
import { previewSize } from '../../lib/helpers/OssImageHelper'

export default {
  components: {
    [Sticky.name]: Sticky,
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      searchKey: '',
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 }
      ],
      list: [
      ]
    }
  },
  computed: {
    userId() {
      return this.$store.state.userInfo.user_poi_users
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
  },
  methods: {
    async getListData(params) {
      params.where = {
        type: 'fur',
        point: {
            '$gt' : 0
        },
        user_poi_users: this.userId,
        seller_poi_sell_users: this.sellerId    
      }
      let res = await this.$api.GetCollect(params)
      this.list = this.list.concat(res.data.items.map((item) => {
        return {
          origin: item.furniture,
          name: item.furniture.fur_name,
          desc: '',
          image: item.furniture.fur_image + previewSize,
          id: item.id,
          sku_id: item.furniture.sku_poi_furniture_sku,
          price: 0,
          fur_id_poi_furnitures: item.furniture.id,
          logic_type: item.logic_type
        }
      }))
      
      return res.data.items.length
    },
    formatPrice(price) {
      return (price/1).toFixed(2);
    },
    onConfirm() {
      this.$refs.item.toggle()
    },
    onSearch() {
      this.$refs.list.refresh()
    },
    // 加入购物车
    async addToCart(e,good) {
      e.stopPropagation();
      let items = [{
        sku_id: good.sku_id,
        fur_id: good.fur_id_poi_furnitures,
        num: 1,
        remark: ''
      }]
      let param = {
        customer_id: good.furniture.logic_type === 'wardrobe' ? 1 : 0,
        items: JSON.stringify(items),
        name: good.name,
        seller_poi_sell_user: this.userId, // TOTO: 加入购物车给的是用户id（接口需要）
        seller_poi_users: this.userId,
        seller_poi_sell_users: this.sellerId,
        type: 'online'
      }
      try{
        await this.$api.AddToCart(param)
        this.$toast('已成功添加到购物车')
      }catch (ex){
        this.$toast(this.$error(ex))
      }
    },
    //取消收藏商品
    async removeToCollect(good) {
      try {
        await this.$api.RemoveToCollect(good.id)
        this.$toast('取消收藏成功')
        this.list = this.list.filter(obj=>obj.id!==good.id)
      } catch (ex) {
        this.$toast(this.$error(ex))
      }
    }
  },
  async activated() {
    this.searchKey = this.$route.query.searchKey
    if (this.searchKey) {
      this.onSearch()
    }
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.back(-1)
      }
    })
  },
  // created() {
  //   this.searchKey = this.$route.query.searchKey
  //   if (this.searchKey) {
  //     this.onSearch()
  //   }
  //   this.$emit('on-nav', {
  //     leftText: '返回',
  //     leftArrow: true,
  //     onClickLeft: () => {
  //       this.$router.go(-1)
  //     }
  //   })
  // }
}
</script>

<style>
  .delete-button {
    height: 100%;
  }
</style>